import React from 'react';
import classnames from 'classnames';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';
import classes from './PricingTable.module.scss';

const RenderPlanHeader = ({ plan }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  return (
    <div className={classes.tableHeader__plan_container}>
      <Typography className={classes.tableHeader__plan_label}>{t(plan.label)}</Typography>
      <Typography weight="medium">{plan.price}</Typography>
    </div>
  );
};

const RenderFeatureSupport = ({ feature, plan }) => {
  const isSupportedMonthly = feature.supportedPlanMonthly.includes(plan.id);
  const isSupportedAnnually = feature.supportedPlanAnnually.includes(plan.id);

  if (isSupportedMonthly && isSupportedAnnually) {
    return <SpriteIcon icon="done" size={16} />;
  }

  if (!isSupportedMonthly && isSupportedAnnually) {
    return (
      <div>
        <SpriteIcon icon="done" size={16} />
      </div>
    );
  }

  if (!feature?.supportedPlanMonthly?.length && !feature?.supportedPlanAnnually?.length) {
    return (
      <div>
        <SpriteIcon icon="lock" size={16} />
      </div>
    );
  }

  return <SpriteIcon icon="lock" size={16} />;
};

const PricingTable = ({
  features,
  plans,
  selectedTab,
  onUpgradeHandler = () => {},
  hideHeader = false,
  hideFooter = false,
  styles = { root: '' },
  onlyBenefits = false,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <table
      className={classnames(classes.pricingTable, {
        [styles.root]: !!styles.root,
      })}
    >
      {!hideHeader && (
        <thead>
          <tr className={classes.tableHeader}>
            {plans.map(plan => (
              <th
                className={classnames(classes.tableHeader__plan, {
                  [classes.tableHeader__cellBorder]: plans?.length > 1,
                })}
                key={plan.id}
              >
                <RenderPlanHeader
                  plan={plan}
                  selectedTab={selectedTab}
                  onUpgradeHandler={onUpgradeHandler}
                  onlyBenefits={onlyBenefits}
                />
              </th>
            ))}
            <th className={classes.tableHeader__feature}>
              <Typography weight="bold">{t(`${TP}.FN_PROFILE_FEATURES`)}</Typography>
            </th>
          </tr>
        </thead>
      )}
      <tbody
        className={classnames(classes.tableBody, {
          [classes.tableBodyWithFooter]: !hideFooter,
        })}
      >
        {features?.map(feature => (
          <tr key={feature.name}>
            {plans?.map(plan => (
              <td className={classes.bodyPlanCell} key={plan.id}>
                <RenderFeatureSupport feature={feature} plan={plan} />
              </td>
            ))}
            <td className={classes.bodyFeatureCell}>{t(feature?.name)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PricingTable;
