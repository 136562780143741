import React, { useEffect, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import Typography from 'components/uiLibrary/Typography';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import SeparatorList from 'components/uiLibrary/SeparatorList';
import LinkButton, { PrimaryButton } from 'components/uiLibrary/LinkButton';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Modal from 'components/uiLibrary/Modal';
import PricingTable from 'components/Globals/PricingTable';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import LogoImage from 'public/images/logo.png';

import queries from 'containers/Globals/queries';

import { useTranslation } from 'src/i18n';
import { useQuery } from 'utils/react-query';
import {
  BASE_PAGE_ROUTES,
  CUSTOMER_LOGO_SCROLL_ID,
  ENTITY_TYPE,
  POPUP_TYPES,
  TP,
  URL_STATIC_PATHS,
} from 'constants/index';
import useAppContext from 'utils/hooks/useAppContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import { getUpdateProfilePath } from 'utils/profileDropdown';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import { useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';

import route from 'constants/routes';

import { artistEditTabValues } from 'containers/Artist/constants';
import { CHECKOUT_TYPES } from 'containers/Accounts/constants';
import { COMPONENTS, SECTIONS, SUB_COMPONENTS } from '../Analytics/constants';
import Testimonials from '../Testimonials';
import CustomerLogos from '../CustomerLogos';
import EntityCard from '../EntityCard';
import EntitySuggestions from '../EntitySuggestions';

import classes from './PlanFeatureComparison.module.scss';

const featureComparisonSection = {
  section: SECTIONS.SUBSCRIPTION_NUDGE,
  component: COMPONENTS.FEATURE_COMPARISON_LIST,
};

const useOnUpgradeHandler = ({ selectedTab, entity }) => {
  const { setSubscriptionNudgesPopup } = useNotificationPopup();
  const source = selectedTab?.section || selectedTab?.value;

  return () => {
    setSubscriptionNudgesPopup({
      isOpen: true,
      popupType: POPUP_TYPES.UPGRADE,
      checkoutType: CHECKOUT_TYPES.PROFILE,
      source,
      targetProfileIdToUpgrade: entity?.id,
    });
  };
};

const ProfileHeader = ({ onBackClickHandler }) => {
  const { entity, entityType } = usePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.profileHeader}>
      <LinkButton
        variant="text"
        leftIcon={<SpriteIcon icon="chevron_left" size={14} />}
        preventDefault
        stopPropagation
        onClick={onBackClickHandler}
        trackingData={{
          ...featureComparisonSection,
          subComponent: SUB_COMPONENTS.BACK_CTA,
        }}
      >
        {t(`${TP}.FN_BACK`)}
      </LinkButton>
      <EntityCard
        entity={entity}
        entityType={entityType}
        width={30}
        height={30}
        transformations={TRANSFORMATIONS.PROFILE_THUMBNAIL}
        typographyProps={{
          name: {
            truncate: true,
          },
          subtext: {
            truncate: true,
          },
        }}
        className={classes.profileImage}
        withoutLink
      />
    </div>
  );
};

const PlatformStatistics = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { data, isLoading } = useQuery(queries.getHomeNumbers());
  const staticInfo = [
    { number: data?.data?.companies?.total, info: `${TP}.m_OPCOS` },
    { number: data?.data?.festivals?.total, info: `${TP}.m_FESTIVALS` },
    { number: data?.data?.artists?.total, info: `${TP}.m_ARTISTS` },
    { number: data?.data?.performances?.total, info: `${TP}.m_PERF` },
    { number: data?.data?.managers?.total, info: `${TP}.m_MANAGERS` },
  ];
  const items = [
    t(`${TP}.FN_OPERA`),
    t(`${TP}.LP_ORCHESTRAS`),
    t(`${TP}.FN_BALLET`),
    t(`${TP}.LP_MUSICALS`),
    t(`${TP}.LP_THEATRE`),
  ];

  if (isLoading) {
    return null;
  }

  return (
    <div className={classes.platformHeader}>
      <div className={classes.platformDetails}>
        <div className={classes.logoContainer}>
          <Image
            src={LogoImage}
            alt="Operabase Home"
            lazy={false}
            className={classes.logoImage}
            disableCloudinary
            disableNextImage
          />
          <Typography color="secondary" className={classes.logoSubtitle} size="12">
            {t(`${TP}.LP_SINCE`)}
          </Typography>
        </div>
        <div className={classes.itemsContainer}>
          <SeparatorList data={items} />
        </div>
      </div>

      <SeparatorList
        styles={{
          root: classes.stats,
        }}
        data={staticInfo.map(item => (
          <Typography>
            {item.number > 0 && item.number} <Typography>{t(item.info)}</Typography>
          </Typography>
        ))}
      />
    </div>
  );
};

const FooterSection = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { entityType, entity } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  return (
    <div className={classes.footer}>
      <div className={classes.testimonialsSection}>
        <Typography>{t(`${TP}.FN_CONVERSION_TESTIMONIALS`)}</Typography>
        {isMobile ? (
          <Testimonials hideTitle limit={3} />
        ) : (
          <HorizontalScrollIndicators
            styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
            triggerSize={0}
            isArrowsEnabled
          >
            <Testimonials hideTitle limit={10} inLine />
          </HorizontalScrollIndicators>
        )}
      </div>
      <div>
        <Typography className={classes.trustedBy}> {t(`${TP}.FN_LOGIN_POPUP_BOTTOM_SECTION_TITLE`)}</Typography>
        <CustomerLogos
          rows={isMobile ? 3 : 1}
          rowHeight={isMobile ? 48 : 40}
          count={isMobile ? 9 : 20}
          allowShowAll={false}
          {...(isMobile
            ? {
                rowGap: 4,
                columnGap: 20,
              }
            : { gap: 4 })}
        />
      </div>
      <div>
        <Typography className={classes.artistProfile}> {t(`${TP}.PREMIUM_ARTIST_PROFILES_TITLE`)}</Typography>
        <EntitySuggestions
          className={classes.entitySuggestions}
          styles={{
            sectionBlock: isMobile ? classes.entitySuggestions__sectionBlock : null,
          }}
          entityType={entityType}
          entity={entity}
          isGrid={isMobile}
          trackingData={featureComparisonSection}
          inline
        />
      </div>
    </div>
  );
};

const FeatureDescription = ({ onUpgradeHandler, onlyBenefits }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { entity, navigate } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();

  return (
    <div className={classes.description}>
      <div className={classes.titleWrapper}>
        <Typography size={isMobile ? 16 : 18} weight="bold">
          {entity?.name
            ? t(`${TP}.PLAN_COMPARISON_TITLE`, { name: entity?.name })
            : t(`${TP}.FN_PAID_PROFILE_BENEFIT_TITLE`)}
        </Typography>
        {onlyBenefits && <PrimaryButton onClick={onUpgradeHandler}>{t(`${TP}.FN_MANAGE_PROFILE`)}</PrimaryButton>}
      </div>
      <ul className={classes.description__benefits}>
        <li>
          <Typography size={isMobile ? 12 : 14}>{t(`${TP}.CASTING_TOOL_PAYWALL_TITLE`)}</Typography>
          <LinkButton
            styles={{ root: classes.viewCustomerListLink }}
            variant="text"
            target="_blank"
            {...navigate.getLinkProps({
              baseRoute: BASE_PAGE_ROUTES.PRO,
              path: URL_STATIC_PATHS.COMPANIES,
              scrollTo: CUSTOMER_LOGO_SCROLL_ID,
            })}
            rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
            trackingData={{
              ...featureComparisonSection,
              subComponent: COMPONENTS.VIEW_CUSTOMER_LIST,
              meta: {
                path: route.INTRO_AOS,
              },
            }}
            isLink
          >
            {t(`${TP}.FN_VIEW_CUSTOMER_LIST`)}
          </LinkButton>
        </li>
        <li>
          <LinkButton
            {...navigate.getLinkProps({ baseRoute: BASE_PAGE_ROUTES.PRO, path: URL_STATIC_PATHS.COMPANIES })}
            variant="text"
            target="_blank"
            rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
            trackingData={{
              ...featureComparisonSection,
              subComponent: COMPONENTS.VIEW_CUSTOMER_TESTIMONIAL,
              meta: {
                path: route.INTRO_AOS,
              },
            }}
            isLink
          >
            <Typography size={isMobile ? 12 : 14} color="link" weight="bold">
              {t(`${TP}.FN_CONVERSION_TESTIMONIALS_VIEW_ALL`)}
            </Typography>
          </LinkButton>
        </li>
        <li>
          <Typography size={isMobile ? 12 : 14}>{t(`${TP}.PLAN_COMPARISON_LIST_ITEM_DESCRIPTION_2_1`)}</Typography>
        </li>
      </ul>
    </div>
  );
};

const PlanSelectionActions = ({ plans, onUpgradeHandler }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { entity } = usePageContext();

  const paidPlan = useMemo(() => {
    if (entity?.subscriptionStatus === 'basic' || entity?.subscriptions?.some(sub => sub?.productId === 4)) {
      return plans[2];
    }
    return plans.find(plan => !plan.isCurrent);
  }, [entity?.subscriptionStatus, entity?.subscriptions, plans]);

  return (
    <div className={classes.selectPlan}>
      <div className={classes.selectPlan__label}>
        <Typography weight="bold" size="12">
          {paidPlan.price}
        </Typography>
        {paidPlan.headline && (
          <Typography size="12" className={classes.selectPlan__label_headline}>
            {t(paidPlan.headline)}
          </Typography>
        )}
      </div>
      <PrimaryButton
        styles={{ root: classes.selectPlan__actions_paidPlan }}
        key={paidPlan.id}
        preventDefault
        stopPropagation
        onClick={onUpgradeHandler}
        trackingData={{
          ...featureComparisonSection,
          subComponent: SUB_COMPONENTS.UPGRADE_TO_PRO,
        }}
      >
        {t(`${TP}.FN_UPGRADE_NOW`)}
      </PrimaryButton>
    </div>
  );
};

const ContinueUpgrade = ({ onUpgradeHandler }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  return (
    <div className={classes.paidPlanBenefits__payButton}>
      <PrimaryButton
        onClick={onUpgradeHandler}
        variant="rectangle"
        id="continueUpgrade"
        rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
      >
        {t(`${TP}.UPGRADE_BUTTON`)}
      </PrimaryButton>
    </div>
  );
};

const PaidPlanBenefits = ({ features, plans, selectedTab, onUpgradeHandler, onlyBenefits }) => {
  const { isMobile } = useDeviceTypeLayouts();
  return (
    <div className={classes.paidPlanBenefits}>
      <FeatureDescription onUpgradeHandler={onUpgradeHandler} onlyBenefits={onlyBenefits} />
      {!isMobile && <ContinueUpgrade onUpgradeHandler={onUpgradeHandler} />}
      <PricingTable
        features={features}
        plans={plans}
        selectedTab={selectedTab}
        onUpgradeHandler={onUpgradeHandler}
        onlyBenefits={onlyBenefits}
      />
      {!isMobile && <ContinueUpgrade onUpgradeHandler={onUpgradeHandler} />}
    </div>
  );
};

const RenderChildren = ({ features, plans, onBackClickHandler, selectedTab, onlyBenefits }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isLoggedIn } = useAppContext();
  const { navigate, entity } = usePageContext();
  const { setIsLoginDialog } = useDialogs();
  const onUpgradeHandler = useOnUpgradeHandler({ selectedTab, entity });
  const { ownerProfile } = useProfileAccesses();
  const { profileData } = useGetTargetProfile(ownerProfile?.profile?.id);
  const manageProfileLinkProps = useMemo(() => {
    if (!onlyBenefits || !isLoggedIn || !profileData?.id) {
      return {};
    }

    let linkPropParams = getUpdateProfilePath({ ...profileData, entityType: ENTITY_TYPE.ARTIST });

    if (linkPropParams.entityType === ENTITY_TYPE.ARTIST) {
      linkPropParams = {
        ...linkPropParams,
        queryParams: { tab: artistEditTabValues.visibilityBoost },
      };
    }

    return linkPropParams;
  }, [onlyBenefits, isLoggedIn, profileData]);

  const plansToRender = useMemo(() => {
    if (onlyBenefits) {
      let upgradeCtaLabel = '';

      if (isLoggedIn) {
        if (manageProfileLinkProps?.entityType) {
          upgradeCtaLabel = t(`${TP}.FN_MANAGE_PROFILE`);
        } else {
          upgradeCtaLabel = t(`${TP}.FN_MANAGE_PROFILE`);
        }
      } else {
        upgradeCtaLabel = `${t(`${TP}.FN_REGISTRATION_POPUP_FOOTER_TEXT`)} ${t(`${TP}.LP_LOGIN_NOW`)}`;
      }

      return plans?.map(plan => ({
        ...plan,
        isCurrent: false,
        ctaLabel: plan?.isCurrent ? '' : upgradeCtaLabel,
      }));
    }

    return plans;
  }, [onlyBenefits, plans, manageProfileLinkProps, isLoggedIn, t]);

  const navigateToActiveProfile = useCallback(() => {
    const linkProps = navigate.getLinkProps(manageProfileLinkProps);
    return navigate.to(linkProps);
  }, [navigate, manageProfileLinkProps]);

  const onClickHandler = useCallback(() => {
    if (onlyBenefits) {
      if (isLoggedIn) {
        if (manageProfileLinkProps.entityType && ownerProfile?.profile?.id) {
          return navigateToActiveProfile();
        }

        return navigate.to(
          navigate.getLinkProps({
            baseRoute: BASE_PAGE_ROUTES.REGISTER,
            onlyLinkProps: true,
          }),
        );
      }

      return setIsLoginDialog({ isOpen: true });
    }

    return onUpgradeHandler();
  }, [
    onlyBenefits,
    onUpgradeHandler,
    isLoggedIn,
    setIsLoginDialog,
    manageProfileLinkProps.entityType,
    ownerProfile?.profile?.id,
    navigate,
    navigateToActiveProfile,
  ]);

  useEffect(() => {
    if (isLoggedIn && onlyBenefits) {
      if (manageProfileLinkProps.entityType === ENTITY_TYPE.ARTIST) {
        navigateToActiveProfile();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className={classnames(classes.root, { [classes.onlyBenefits]: onlyBenefits })}>
      {!onlyBenefits && (
        <>
          <ProfileHeader onBackClickHandler={onBackClickHandler} />
          <PlatformStatistics />
        </>
      )}

      <PaidPlanBenefits
        features={features}
        plans={plansToRender}
        selectedTab={selectedTab}
        onUpgradeHandler={onClickHandler}
        onlyBenefits={onlyBenefits}
      />
      {!onlyBenefits && (
        <>
          <FooterSection />
          <PlanSelectionActions plans={plans} onUpgradeHandler={onClickHandler} />
        </>
      )}
    </div>
  );
};

const PlanFeatureComparison = ({
  features,
  plans,
  onBackClickHandler,
  selectedTab,
  isOpen,
  isPage = true,
  onlyBenefits = false,
}) => {
  const { isMobile } = useDeviceTypeLayouts();

  if (!onlyBenefits && (!isPage || isMobile)) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onBackClickHandler}
        styles={{
          modalContainer: classnames(classes.modalContainer),
          modalRoot: classes.modalRoot,
          drawerPaper: classes.drawer,
        }}
        allowMobileDrawer
      >
        <RenderChildren
          features={features}
          plans={plans}
          onBackClickHandler={onBackClickHandler}
          selectedTab={selectedTab}
        />
      </Modal>
    );
  }

  return (
    <RenderChildren
      features={features}
      plans={plans}
      onBackClickHandler={onBackClickHandler}
      selectedTab={selectedTab}
      onlyBenefits={onlyBenefits}
    />
  );
};

export default PlanFeatureComparison;
